.popupbackground {
    width: 100%;
    height: 100%;
    background-color: #202020e6;
    position: fixed;
    display: flex;
    z-index: 9999999999;
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
  }

  .cntnebtn{
  background: #c2114b; /*  background */
  color: white; /* White text */
  padding: 12px 24px; /* Button padding */
  font-size: 16px; /* Font size */
  font-weight: bold;
  border: none; /* Remove border */
  border-radius: 8px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth hover transition */
  margin-top: 32px;
  }

  .cntnebtn:hover {
    background-color: #d64576; /* Darker green on hover */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Shadow on hover */
  }

  .cntnebtn:focus {
    outline: none; /* Remove outline */
  }
  
  .cntnebtn:active {
    background-color: #d64576; /* Even darker green on click */
  }
  
  .popupcontainer {
    width: 628px;
    /* height: 73%; */
    /* min-height: 36rem; */
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 15px #77a1f0;
    display: flex;
    flex-direction: column;
    padding: 20px;
    position: relative;
    border: 3px solid #6495ed;
  }

  .paymentOptions {
    height: 23px;
    width: 60% !important;
  }

  .popupcontainer2 {
    width: 628px;
    height: 85%;
    /* min-height: 36rem; */
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 15px #77a1f0;
    display: flex;
    flex-direction: column;
    padding: 20px;
    position: relative;
    border: 3px solid #6495ed;
  }
  
  .error-message {
    color: red;
    font-size: 12px;
    margin-top: 8px;
  }

  .popupcontainer button.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #ff5f5f;
    border: none;
    color: white;
    font-size: 16px;
    width: 24px;
    height: 24px;
    text-align: center;
    cursor: pointer;
    border-radius: 50%;
  }
  
  .popupcontainer h4 {
    font-weight: bold;
  }
  
  .paymentOptionSection {
    background: #E5E5EA;
    border-radius: 5px;
    margin-top: 10%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: 18rem;
  }
  
  .paymentOption {
    background: white;
    border-radius: 5px;
    display: flex;
    margin-top: 2%;
    align-items: center;
    padding: 10px;
    gap: 10px;
    box-shadow: 0 0 5px #aaa;
    height: 6rem;
  }

  .paymentOption2{
    background: white;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 10px;
    box-shadow: 0 0 5px #aaa;
    height: 6rem;
    /* min-height: 5rem; */
  }

  .paymentOption2 img {
    width: 60px;
    height: auto;
  }
  
  .paymentOption img {
    width: 60px;
    height: auto;
  }

  .paymentOption2 div {
    display: flex;
    flex-direction: column;
  }
  
  .paymentOption div {
    display: flex;
    flex-direction: column;
  }

  .paymentOption2    div p {
    margin: 0;
    font-weight: bold;
  }
  
  .paymentOption div p {
    margin: 0;
    font-weight: bold;
  }

  .paymentOption2 div p, .paymentOption2 div span {
    font-size: 14px;
  }
  
  
  .paymentOption div p, .paymentOption div span {
    font-size: 14px;
  }

  .paymentOption2 div:last-child {
    margin-left: auto;
    cursor: pointer;
  }
  
  .paymentOption div:last-child {
    margin-left: auto;
    cursor: pointer;
  }

  .paymentOption2 div:last-child img {
    width: 20px;
    /* height: auto; */
  }
  
  .paymentOption div:last-child img {
    width: 20px;
    height: auto;
  }
  
  .input-group {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
  
  input[type="text"] {
    height: 30px;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 0 10px;
  }
  
  /* button.apply-btn {
    border-radius: 5px;
    font-size: 13px;
    width: 80px;
    height: 30px;
    font-weight: bold;
    border: none;
    color: white;
    background: rgb(179 179 186);
    cursor: pointer;
  } */
  
  .apply-btn {
    border-radius: 5px;
    font-size: 13px;
    width: 80px;
    height: 30px;
    font-weight: bold;
    border: none;
    color: white;
    background: rgb(179 179 186); /* Default color */
    cursor: pointer;
    transition: background 0.3s;
  }
  
  /* Highlighted state for the apply button */
  .apply-btn.highlighted {
    background-color: #4CAF50; /* Green background for highlighted state */
    color: white;
  }