.gig-preview {
  margin: auto;
  display: flex;
  padding: 18px 0;
  border-bottom: 1px solid #bab4b4;
}

.edit-basicdetails-section-main {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.edit-basicdetails-section-main h6 {
  font-size: 16px;
  color: #333;
  margin: 10px 0;
  font-weight: 500;
}

.edit-basicdetails-section-main h6 span {
  font-weight: 400;
  color: #555;
}

.edit-basicdetails-section-main h6::before {
  content: "• ";
  color: #007bff;
  font-size: 18px;
  margin-right: 8px;
}

.rdw-editor-wrapper {
  box-sizing: content-box;
  border-radius: 8px;
  border: 1px dashed #aaa;
}
.gig-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.gig-image {
  border-radius: 10px;
  margin-right: 20px;
  width: 250px;
}
.gig-name {
  color: #000;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  font-weight: 600;
}
.gig-description {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  padding: 30px 0;
}
.gig-infos {
  display: flex;
  justify-content: space-between;
}
.gig-followers {
  display: flex;
  padding-right: 15px;
}
.gig-logo {
  border-radius: 50%;
  border: 1px solid rgb(0 0 0 / 0.2);
  background: #ddd;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gig-logo > img {
  width: 18px;
  height: 18px;
}
.followers-text {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.gig-counts {
  padding-left: 12px;
}
.followers-count {
  color: #c2114b;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 5px;
}
.brands-preview-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}
.company {
  width: 650px;
}
.company {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}
.company-name {
  display: flex;
  align-items: center;
}
.name-wrapper {
  width: 27px;
  height: 27px;
  flex-shrink: 0;
  background-color: #c2114b;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.name-wrapper {
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.company-text {
  color: #181818;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding-left: 6px;
}
.company-plan {
  color: #c2114b;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.trial-page {
  display: flex;
  justify-content: space-between;
  padding: 60px 0;
}
.trial-title {
  color: #000;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.inlcluded {
  color: #c2114b;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.333px;
  text-decoration-line: underline;
  padding: 30px 0;
}
.included-content {
  display: flex;
}
.included-line {
  display: flex;
  align-items: center;
}
.include-text {
  padding-left: 8px;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.25px;
  padding: 10px 0 10px 10px;
}
.trialing > img {
  width: 350px;
  height: 350px;
  flex-shrink: 0;
}
.trial-model {
  display: flex;
  align-items: center;
}
.include-wrapper {
  padding-left: 30px;
}
.gmail-wrapper {
  width: 96%;
  border-radius: 10px;
  background: #fff;
  box-shadow: 1px 8px 24px 0 rgb(0 0 0 / 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 30px;
  margin-top: 30px;
  height: 90%;
}
.gmail-tick > img {
  max-width: 130px;
  width: auto;
  height: auto;
  flex-shrink: 0;
}
.done {
  color: #000;
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 10px 0;
  margin: 0 0 15px;
}
.gmail-info {
  color: #000;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  padding-bottom: 25px;
  max-width: 50%;
  width: 100%;
}
.open-gmail {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  min-width: 150px;
  height: 50px;
  border-radius: 60px;
  border: 5px solid #000;
  background: #000;
  padding: 0 20px;
}
.open-gmail img {
  margin-right: 15px;
}
.gmail-btn-text {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.gig-input {
  display: none;
}
.image-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.image-preview > img {
  width: 300px;
}
.dialog-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: inherit;
  margin: 15px 0 0 0px;
  min-height: 90vh;
}
.brands-form-wrapper {
  width: 640px;
  margin-top: 100px;
}
.has-search .form-control {
  padding-left: 2.375rem;
}
.form-group.has-search {
  position: relative;
}
.has-search .form-control-feedback {
  position: absolute;
  display: block;
  width: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  height: 100%;
  line-height: 44px;
}
.adult-signup-main {
  width: 100%;
  max-width: 40%;
  border: 1px solid #aaa;
  border-radius: 20px;
  padding: 15px 25px 25px;
  border-radius: 20px;
}
.adult-signup-main .step-title {
  padding: 0;
  margin: 10px 0;
}
.adult-password-wrapper,
.adult-confirm-password-wrapper {
  position: relative;
}
.show-password-icon {
  position: absolute;
  top: 15px;
  right: 10px;
  color: #aaa;
  cursor: pointer;
}
.show-confirm-password-icon {
  position: absolute;
  top: 15px;
  right: 10px;
  color: #aaa;
  cursor: pointer;
}
.form-control,
.form-select {
  outline: 0 !important;
  box-shadow: inherit !important;
}
.login-main .form-label {
  margin: 0;
}
.adult-signup-inputs {
  height: 50px;
  border: 1px solid #b1a8a8;
}
.signup-btn-section {
  padding: 20px 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.signup-btn-section .signup-btn {
  background-color: #c2114b;
  border-radius: 14px;
  cursor: pointer;
  padding: 6px 24px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.adult-signup-main.widFull {
  width: 100%;
  max-width: 100%;
}
.login-main.regPage {
  align-items: center;
  display: flex;
  height: auto;
  justify-content: center;
  min-height: 96vh;
  padding: 60px 0 70px;
}

@media (max-width: 991px) {
  .adult-signup-main {
    width: 100%;
    max-width: 60%;
  }
  .trial-page {
    padding: 60px 20px;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .trial-page {
    width: 100%;
    flex-direction: column;
  }
  .login-main.regPage {
    padding: 25px 15px;
    width: 100% !important;
  }
  .dialog-body {
    min-height: 100%;
    margin: 45px 0 85px !important;
  }
  .dialog-body.spaceTops {
    margin-top: 0;
  }
  .material-mobile-style {
    border: 1px solid #d3d3d3;
    border-radius: 3px;
    height: 45px;
  }
}

@media (max-width: 480px) {
  .adult-signup-main {
    width: 95%;
    padding: 10px 20px;
  }
  .gmail-info {
    width: 95%;
  }
  .gmail-wrapper {
    margin-top: 0;
  }
  .trial-page {
    display: flex;
    justify-content: space-between;
    padding: 0 0;
    flex-direction: column;
    flex-direction: column-reverse;
  }
  .trialing {
    display: flex;
    justify-content: center;
    margin-top: 0;
  }
  .trialing > img {
    width: 250px;
    height: 250px;
    flex-shrink: 0;
  }
  .trial-wrapper {
    padding: 30px;
  }
}
.password_strength_box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  place-items: center;
  align-items: center;
  margin: 0px 0;
  display: none !important;
  opacity: 0;
}
.password_strength_box .password_strength {
  width: 100%;
}
.password_strength .text {
  font-size: 13px;
  text-align: left;
  padding: 0 0 6px 0;
  font-weight: 500;
  color: red;
  margin-bottom: 0px;
}
.password_strength .line_box {
  background-color: #f2f2f2;
}
.password_strength .line {
  width: 10%;
  height: 3px;
  border-radius: 100px;
  background-color: red;
  transition: 0.3s;
}
.tool_tip_box {
  cursor: pointer;
  margin-left: 10px;
  position: relative;
  margin-top: 18px;
}
.tool_tip_box span {
  display: inline-block;
}
.tool_tip_box .tool_tip {
  position: absolute;
  width: 200px;
  top: 30px;
  right: -100px;
  background-color: #f6f6f6;
  padding: 10px 30px;
  z-index: 10;
  border-radius: 12px;
  text-align: left;
  line-height: 1.5;
  box-shadow: 0 5px 10px -5px #000;
  pointer-events: none;
  opacity: 0;
  transition: 0.2s;
  transition-delay: 0.5s;
}
.tool_tip_box:hover .tool_tip {
  opacity: 1;
}
.tool_tip p {
  display: list-item;
}
.material-mobile-style {
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  height: 45px;
  padding: 0px 0px 0px 10px;
  width: 100%;
}

@media (max-width: 767px) {
  .dialog-body.spaceTops {
    margin-top: 0;
    padding: 0;
  }
  .adult-signup-main,
  .gmail-info {
    max-width: 70%;
  }

  .trial-page .included-content {
    display: flex;
    flex-direction: column;
  }
  .trial-page .included-content .include-wrapper {
    padding-left: 0;
  }
  .home-add-title-wrapper {
    padding-left: 0;
    float: left;
    width: calc(100% - 100px);
  }
}
@media (max-width: 580px) {
  .brand-home-one .btnSpace {
    margin: 15px 0 0;
  }
}
@media (max-width: 480px) {
  .adult-signup-main,
  .gmail-info {
    max-width: 90%;
    padding: 10px 20px;
  }

  .gmail-wrapper {
    margin-top: 0;
  }
  .trial-page {
    display: flex;
    justify-content: space-between;
    padding: 0 0;
    flex-direction: column;
    flex-direction: column-reverse;
  }
  .trialing {
    display: flex;
    justify-content: center;
    margin-top: 0;
  }
  .trialing > img {
    width: 250px;
    height: 250px;
    flex-shrink: 0;
  }
  .trial-wrapper {
    padding: 30px;
  }

  .brand-home-one .btnSpace {
    justify-content: center;
    margin: 15px 0 0;
  }
}
@media (max-width: 380px) {
  .home-add-title-wrapper {
    padding: 10px 0 0;
    float: left;
    width: 100%;
  }
}
/* .material-mobile-style {
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  height: 45px;
  padding: 0px 0px 0px 10px;
} */
