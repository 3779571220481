.plan-main {
  margin: 0 0 35px 0;
  width: 100%;
}
.kids-pan-section {
  margin-top: 30px;
}
.plans-wrapper {
  width: 100%;
  border-radius: 20px;
  padding: 20px 40px 30px;
  background: #fff;
  box-shadow: 2px 4px 24px 0 rgb(0 0 0 / 0.25);
  cursor: pointer;
  height: auto;
  min-height: 97%;
}
.plans-section .col-md-4:first-child {
  width: 29%;
}
.plans-section .col-md-4:nth-child(2),
.plans-section .col-md-4:nth-child(3) {
  width: 35.5%;
}
.bordered {
  border: 3px solid #c2114b !important;
}
.subscribe-wrapper {
  border: none !important;
}
.choose-btn {
  width: 100%;
  height: 48px;
  flex-shrink: 0;
  border-radius: 60px;
  border: 1px solid #fff;
  background: #000;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 0;
}
.free-plans .choose-btn,
.plans-section .col-md-4:first-child .choose-btn {
  background-color: #add8e6;
  color: #fff;
}
.pro-plans .choose-btn,
.plans-section .col-md-4:nth-child(2) .choose-btn {
  background-color: #9370db;
  color: #fff;
}
.premium-plans .choose-btn,
.plans-section .col-md-4:nth-child(3) .choose-btn {
  background-color: gold;
  color: #fff;
}
.subscriptions-wrapper .col-md-4:first-child .plans-wrapper {
  border-top: 11px solid #add8e6 !important;
}
.subscriptions-wrapper .col-md-4:nth-child(2) .plans-wrapper {
  border-top: 11px solid #9370db !important;
}
.subscriptions-wrapper .col-md-4:nth-child(3) .plans-wrapper {
  border-top: 11px solid #ffd700 !important;
}
.subscriptions-wrapper .plan-name {
  min-height: 55px;
}
.subscribe-form {
  margin-bottom: 50px;
}
.subcribe-gift {
  color: #c2114b !important;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.25px;
  padding-bottom: 20px;
}
.disabled-plan {
  opacity: 0.5;
  cursor: unset !important;
}
.first-plan-choose {
  margin-top: 64px !important;
}
.explore-info {
  padding: 10px 0;
}
.fixImgs {
  position: relative;
}
.fixImgs .kids-image-sticky {
  position: fixed;
  max-width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 280px;
}
@media only screen and (max-width: 1399px) {
  .fixImgs .kids-image-sticky {
    width: 18%;
    max-width: 100%;
  }
}
@media only screen and (max-width: 1199px) {
  .kids-form.col-md-8 {
    padding-left: 3%;
  }
}
@media only screen and (max-width: 1149px) {
  .kids-form.col-md-8 {
    padding-left: 10px;
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .kidsform-one .kids-wrapper .kids-form .kids-form-title::after {
    width: 37%;
    margin-left: 15px;
  }
  .plans-section .col-md-4:first-child,
  .plans-section .col-md-4:nth-child(2),
  .plans-section .col-md-4:nth-child(3) {
    width: 100%;
  }
  .priceHeight {
    min-height: 80px;
  }
}

.plan-main {
  margin: 0 0 35px 0;
  width: 100%;
}

.kids-pan-section {
  margin-top: 30px;
}

.plans-wrapper {
  width: 100%;
  border-radius: 20px;
  padding: 20px 40px 30px;
  background: #fff;
  box-shadow: 2px 4px 24px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;

  height: auto;
  min-height: 97%;
}
.plans-section .col-md-4:first-child {
  width: 29%;
}
.plans-section .col-md-4:nth-child(2),
.plans-section .col-md-4:nth-child(3) {
  width: 35.5%;
}

.plans-section .col-md-4:first-child .icPrice,
.plans-section .col-md-4:first-child .subcribe-gift {
  color: #add8e6;
}

.plans-section .col-md-4:nth-child(2) .icPrice,
.plans-section .col-md-4:nth-child(2) .subcribe-gift {
  color: #9370db;
}

.plans-section .col-md-4:nth-child(3) .icPrice,
.plans-section .col-md-4:nth-child(3) .subcribe-gift {
  color: #ffd700;
}

.bordered {
  border: 3px solid #c2114b !important;
}

.subscribe-wrapper {
  border: none !important;
}

.choose-btn {
  width: 100%;
  height: 48px;
  flex-shrink: 0;
  border-radius: 60px;
  border: 1px solid #fff;
  background: #000;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 0;
}
.free-plans .choose-btn,
.plans-section .col-md-4:first-child .choose-btn {
  background-color: #add8e6;
  color: #fff;
}
.pro-plans .choose-btn,
.plans-section .col-md-4:nth-child(2) .choose-btn {
  background-color: #9370db;
  color: #fff;
}
.premium-plans .choose-btn,
.plans-section .col-md-4:nth-child(3) .choose-btn {
  background-color: #ffd700;
  color: #fff;
}

.subscriptions-wrapper .col-md-4:first-child .plans-wrapper {
  border-top: 11px solid #add8e6 !important;
}
.subscriptions-wrapper .col-md-4:nth-child(2) .plans-wrapper {
  border-top: 11px solid #9370db !important;
}
.subscriptions-wrapper .col-md-4:nth-child(3) .plans-wrapper {
  border-top: 11px solid #ffd700 !important;
}

.subscriptions-wrapper .plan-name {
  min-height: 55px;
}

.subscribe-form {
  margin-bottom: 50px;
}

.subcribe-gift {
  color: #c2114b;

  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  padding: 0;
  margin: 7px 0 10px;
}
.disabled-plan {
  opacity: 0.5;
  cursor: unset !important ;
}

.videos-label {
  color: #000;
  text-align: left;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  padding: 5px 0px;
}

.explore-info {
  padding: 10px 0px;
}

.fixImgs {
  position: relative;
}

.fixImgs .kids-image-sticky {
  position: fixed;
  object-fit: cover;
  width: 280px;
  max-width: 100%;
}
.url-file-wrapper {
  display: flex;
  align-items: baseline;
  margin: 5px 0px;
  padding: 0;
}

@media only screen and (max-width: 1399px) {
  .fixImgs .kids-image-sticky {
    width: 18%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 1199px) {
  .kids-form.col-md-8 {
    padding-left: 3%;
  }
}
@media only screen and (max-width: 1149px) {
  .kids-form.col-md-8 {
    padding-left: 10px;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .plans-wrapper {
    padding: 20px;
  }
  .subscriptions-wrapper .plan-name {
    min-height: auto;
    margin: 0;
    padding: 10px 0;
  }
  .kidsform-one .kids-wrapper .kids-form .kids-form-title::after {
    width: 100%;
    margin-left: 15px;
  }
  .fixImgs {
    text-align: center;
  }
  .fixImgs .kids-image-sticky {
    position: relative;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }
  .plans-section .col-md-4:first-child,
  .plans-section .col-md-4:nth-child(2),
  .plans-section .col-md-4:nth-child(3) {
    width: 100%;
  }
  .priceHeight {
    min-height: 80px;
  }
}

.material-mobile-style {
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  height: 45px;
  padding: 0px 0px 0px 10px;
}

.add-vidoe-icon {
  color: #c2114b;
  font-size: 25px;
  cursor: pointer;
  padding-left: 15px;
}

.plans-wrapper {
  width: 100%;
  border-radius: 20px;
  padding: 20px 40px 30px;
  background: #fff;
  box-shadow: 2px 4px 24px 0 rgb(0 0 0 / 0.25);
  cursor: pointer;
  height: auto;
  min-height: 97%;
}
