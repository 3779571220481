@charset "UTF-8";
.kidsform-one .select__control {
  font-size: 15px;
  min-height: 45px;
}
.kidsform-one .kids-wrapper {
  margin: 80px 0 30px 0;
  display: flex;
}
.kidsform-one .kids-wrapper .kids-form .kids-title,
.kidsform-one .kids-wrapper .kids-form .kids-form-title {
  color: #000;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 20px 0;
  width: 100%;
  display: flex;
  align-items: center;
}
.kidsform-one .kids-wrapper .kids-form .kids-form-title-sub {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 20px 0;
  width: 100%;
}

.edit-basicdetails-section-main {
  display: flex;
  justify-content: center; /* Center the card */
  padding: 16px; /* Add some padding around */
}

.payment-details-card {
  background-color: #fff; /* White background for the card */
  border: 1px solid #ccc; /* Light border */
  border-radius: 8px; /* Rounded corners */
  padding: 20px; /* Inner padding */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Shadow for depth */
  width: 100%; /* Full width */
  max-width: 600px; /* Max width for larger screens */
  /* margin: 0 auto; Center the card */
}

.payment-detail {
  display: flex;
  justify-content: space-between; /* Space between label and value */
  margin: 12px 0; /* Space between lines */
}

.payment-detail span {
  color: #333; /* Darker text for readability */
}

.payment-detail span:nth-child(2) {
  font-weight: bold; /* Highlight the values */
  color: #007bff; /* Bootstrap primary color for emphasis */
}
.kidsform-one .kids-wrapper .kids-form .kids-notes-two {
  padding: 20px 0;
}
.kidsform-one .kids-wrapper .kids-form .kids-form-title {
  font-size: 22px;
  padding: 15px 0 0 0;
}
.kidsform-one .kids-wrapper .kids-form .splitter {
  padding: 20px 0;
}
.kidsform-one .kids-wrapper .kids-form .category-name {
  padding: 8px 16px;
  background-color: #dbdbdb;
  border-radius: 4px;
  margin: 5px 5px 5px 0;
}
.kidsform-one .kids-wrapper .kids-form .selected-category {
  padding: 8px 16px;
  background-color: #c2114b;
  margin: 5px 5px 5px 0;
  cursor: pointer;
  color: #fff;
  border-radius: 4px;
}
.kidsform-one .kids-wrapper .kids-form .dynamic-profession {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  max-width: 100%;
}

.listsub {
  font-size: 14px;
  color: #afafaf;
  font-weight: 600;
}
.kidsform-one .kids-wrapper .kids-form .profession-input {
  max-width: 145px;
  margin-right: 15px !important;
}
.kidsform-one .kids-wrapper .kids-form .offer-wrapper {
  display: flex;
  align-items: center;
}
.kidsform-one .kids-wrapper .kids-form .offer-label {
  font-size: 14px;
  padding-left: 8px;
  cursor: pointer;
  margin-bottom: 0px !important;
}
.kidsform-one .kids-wrapper .kids-form .profession-btn {
  display: flex;
  justify-content: end;
  margin: 15px 0;
  max-width: 700px;
}
.kidsform-one .kids-wrapper .kids-form .submit-profession {
  background-color: #c2114b;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  border-radius: 25px;
  padding: 4px 12px;
  border: none !important;
}
.form-label {
  margin-bottom: 0.2rem;
}
.password-wrong {
  color: red;
  padding: 4px 0;
  font-size: 14px;
}
.placeholder {
  color: #999;
}
.public-DraftStyleDefault-ltr {
  padding-left: 15px !important;
}
.thread-image {
  max-width: 20px;
  height: 20px;
}
.thread-fill {
  max-width: 30px;
  height: 30px;
}
.profession-checkbox {
  width: 20px;
  height: 20px;
}
.offer-wrapper {
  padding-top: 15px;
}
.fileType > img {
  max-width: 25px;
  height: 25px;
}
@media only screen and (max-width: 1150px) {
  .kids-img {
    display: none;
  }
}
.kids-main {
  width: 100%;
}
.kids-main .kids-form-row {
  display: flex;
  justify-content: space-between;
}
.kids-main .profession-content-section {
  width: 100%;
}
.kids-main .category-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.kids-main .rich-editor {
  width: 100%;
  margin-bottom: 30px;
}
.kids-main .cv-section {
  border-radius: 8px;
  border: 1px dashed #aaa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
  width: 100%;
  min-height: 275px;
}
.kids-main .cv-section .select-cv-input {
  display: none;
}
.kids-main .upload-backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #000;
  padding: 20px;
  border-radius: 50%;
}
.kids-main .upload-text {
  padding: 16px 0;
  text-align: center;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.kids-main .uploaded-file-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 10px 15px;
  width: 100%;
}
.kids-main .file-section,
.kids-main .file-options {
  display: flex;
}
.kids-main .fileName {
  padding-left: 10px;
}
.kids-main .option-menu {
  padding: 0 10px;
  cursor: pointer;
}
.kids-main .features-section {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.kids-main .features-section .features-input-wrapper {
  margin-right: 10px;
}
.kids-main .media-wrapper {
  cursor: pointer;
  border: 1px solid #d3d3d3;
  /* display: flex; */
  justify-content: space-between;
  margin: 10px 0;
  align-items: center;
  border-radius: 6px;
  padding: 15px;
}
.kids-main .media-wrapper .connect-btn {
  background-color: #000;
  border-radius: 25px;
  cursor: pointer;
  /* visibility: hidden; */
  padding: 6px 14px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.kids-main .media-wrapper .media-info {
  display: flex;
  align-items: center;
  width: 100%;
}
.kids-main .media-wrapper .media-info .media-text {
  /* padding-left: 15px; */
  width: 100%;
  font-size: 15px;
}
.kids-main .media-wrapper:hover .connect-btn {
  visibility: visible;
}
.kids-main .Or-seperator {
  color: #aaa;
  font-size: 16px;
  text-align: center;
  padding: 20px 0;
}
.kids-main .verification-section {
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #aaa;
  border-radius: 6px;
  margin: 25px 0;
  width: 100%;
}
.kids-main .verification-section .id-text {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}
.kids-main .verification-section .id-image {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 20px;
  border-radius: 50%;
  background-color: #000;
}
.kids-main .verification-section .id-choose {
  background-color: #000;
  border-radius: 25px;
  cursor: pointer;
  padding: 12px 24px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.kids-main .verification-section .select-cv-input {
  display: none;
}
.kids-main .verification-status {
  text-align: end;
  color: red;
}
.kids-main .signup-btn-section {
  margin-bottom: 100px;
  padding: 20px 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.kids-main .signup-btn-section .signup-btn {
  background-color: #c2114b;
  border-radius: 14px;
  cursor: pointer;
  padding: 6px 24px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
@media (min-width: 320px) and (max-width: 480px) {
  .kidsform-one .kids-wrapper .kids-form .profession-input {
    max-width: 145px;
    margin-right: 15px !important;
  }
  .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedStart.css-1eed5fa-MuiInputBase-root-MuiInput-root {
    height: 32px !important;
    align-items: center !important;
  }
  .features-section {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .features-section .features-input-wrapper {
    margin-right: 10px;
    width: 45%;
  }
  .uploaded-file-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding: 10px 0px !important;
    width: 100%;
  }
  .kids-main {
    width: 100%;
  }
  .kids-main .kids-form-row {
    display: flex;
    justify-content: unset;
    flex-direction: column;
  }
  .kids-main .kids-form-row .kids-form-section {
    width: 100%;
  }
  .verification-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    /* margin-bottom: 100px !important; */
  }
  .verification-section .id-text {
    padding: 26px 0;
  }
  .verification-section .select-cv-input {
    display: none;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .kidsform-one .kids-wrapper {
    margin: 0px 0;
    display: flex;
  }
  .material-mobile-style {
    border: 1px solid #d3d3d3;
    border-radius: 3px;
    height: 45px;
    padding: 6px 0px 0px 10px !important;
  }
  .kidsform-one .kids-wrapper .kids-form .profession-input {
    max-width: 145px;
    margin-right: 15px !important;
  }
  .verification-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }
  .verification-section .id-text {
    padding: 26px 0;
  }
  .verification-section .select-cv-input {
    display: none;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .kidsform-one .kids-wrapper .kids-form .profession-input {
    max-width: 145px;
    margin-right: 15px !important;
  }
  .kids-main {
    width: 100%;
  }
  .kids-main .kids-form-row {
    display: flex;
    justify-content: unset;
    flex-direction: column;
  }
  .kids-main .kids-form-row .kids-form-section {
    width: 100%;
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
  .kidsform-one .kids-wrapper .kids-form .profession-input {
    max-width: 145px;
    margin-right: 15px !important;
  }
  .kids-main {
    width: 100%;
  }
  .kids-main .kids-form-row {
    display: flex;
    justify-content: unset;
    flex-direction: column;
  }
  .kids-main .kids-form-row .kids-form-section {
    width: 100%;
  }
}
@media (max-width: 1200px) {
  .kidsform-one .kids-wrapper .kids-form .profession-input {
    max-width: 145px;
    margin-right: 15px !important;
  }
  .kidsform-one .kids-wrapper {
    margin: 65px 0 30px;
    display: flex;
  }
  .media-wrapper {
    margin: 10px 0px !important;
  }
}
.elipsis-icon {
  padding: 10px;
}
.disabledButton {
  opacity: 0.5;
  cursor: not-allowed;
}
.invalid-fields {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
.mandatory {
  color: #dc3545;
}
.upload-info {
  text-align: center;
}

.profession-section-cover {
  box-shadow: rgb(0 0 0 / 0.02) 0 1px 3px 0, rgb(27 31 35 / 0.15) 0 0 0 1px;
  padding: 15px;
  border-radius: 6px;
  background-color: #f8f8f8;
  margin-top: 5px;
}
.fixImgs {
  position: relative;
}
.fixImgs .kids-image-sticky {
  position: fixed;
  max-width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 280px;
  max-width: 100%;
}

.css-1eed5fa-MuiInputBase-root-MuiInput-root::before {
  border-bottom: unset !important;
}
.css-jcincl {
  border-bottom: unset !important;
}
.css-jcincl::before {
  border-bottom: unset !important;
}
.css-1eed5fa-MuiInputBase-root-MuiInput-root {
  width: 100% !important;
}
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100% !important;
}
.css-i44wyl {
  width: 100% !important;
}
.profession-input-adult {
  max-width: 200px;
  margin-right: 65px !important;
}
.profession-checkbox-adult {
  width: 20px !important;
  height: 20px !important;
}
.adult-img-img {
  position: fixed;
}
.password_strength_box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  place-items: center;
  align-items: center;
  margin: 0px 0;
  display: none !important;
  opacity: 0;
}
.password_strength_box .password_strength {
  width: 100%;
}
.password_strength .text {
  font-size: 13px;
  text-align: left;
  padding: 0 0 6px 0;
  font-weight: 500;
  color: red;
  margin-bottom: 0px;
}
.password_strength .line_box {
  background-color: #f2f2f2;
}
.password_strength .line {
  width: 10%;
  height: 3px;
  border-radius: 100px;
  background-color: red;
  transition: 0.3s;
}
.tool_tip_box {
  cursor: pointer;
  margin-left: 10px;
  position: relative;
  margin-top: 18px;
}
.tool_tip_box span {
  display: inline-block;
}
.tool_tip_box .tool_tip {
  position: absolute;
  width: 200px;
  top: 30px;
  right: -100px;
  background-color: #f6f6f6;
  padding: 10px 30px;
  z-index: 10;
  border-radius: 12px;
  text-align: left;
  line-height: 1.5;
  box-shadow: 0 5px 10px -5px #000;
  pointer-events: none;
  opacity: 0;
  transition: 0.2s;
  transition-delay: 0.5s;
}
.tool_tip_box:hover .tool_tip {
  opacity: 1;
}
.tool_tip p {
  display: list-item;
}
.profile-image-edit-section {
  margin: 20px;
  width: 300px;
  max-width: 100%;
  position: relative;
}
.profile-image-edit-section .profile-image-edit {
  border-radius: 5%;
  width: 250px;
}
.profile-image-edit-section .btn-img-edit-wrapper {
  display: flex;
  justify-content: end;
}
.profile-image-edit-section .btn-img-edit-wrapper .edit-profileimg-btn {
  background-color: #c2114b;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
  text-align: right;
  padding: 7px 15px;
}
.profile-image-edit-section .btn-img-edit-wrapper .edit-profileimg-activate {
  background-color: green;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
  text-align: right;
}
.profile-image-edit-section .btn-img-edit-wrapper .update-profileimg-btn {
  background-color: #c2114b;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
  text-align: center;
  min-width: 133px;
  margin-left: 0;
}
.select-cv-input {
  display: none;
}
.edit-profileimg-btn {
  background-color: #c2114b;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
  text-align: right;
  padding: 7px 15px;
  min-width: 100px;
}
.update-profile-flex {
  display: flex;
  justify-content: end;
}
.kids-form-title {
  color: #000;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 20px 0;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
.kids-form-title::after {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  margin: 0;
  border: 1px solid #000;
  position: absolute;
  z-index: -1;
}
.kids-form-title > span {
  display: inline-block;
  background-color: #fff;
  padding: 5px 15px 5px 0;
}
.kids-form-title-sub {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 20px 0;
  width: 100%;
}
.kids-notes-two {
  padding: 20px 0;
}
.kids-form-title {
  font-size: 22px;
  padding: 15px 0 0;
}
.features-section .features-input-wrapper {
  min-width: 150px;
}
.mr-3 {
  margin-right: 1rem;
}
.kids-title {
  margin: 0 !important;
  padding-top: 0 !important;
}
.splitter {
  padding: 20px 0;
}
.category-name,
.selected-category {
  padding: 8px 16px;
  background-color: #dbdbdb;
  margin: 8px 5px 0 0;
  cursor: pointer;
  border-radius: 4px;
  font-size: 13px;
  display: inline-flex;
  align-items: center;
  min-height: 40px;
}
.selected-category {
  background-color: #c2114b;
  color: #fff;
}
.dynamic-profession {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  max-width: 700px;
}
.profession-input {
  max-width: 200px;
  margin-right: 15px !important;
}
.offer-wrapper {
  display: flex;
  align-items: center;
}
.offer-label {
  font-size: 14px;
  padding-left: 15px;
  cursor: pointer;
  margin-bottom: 0px !important;
}
.profession-btn {
  display: flex;
  justify-content: end;
  margin: 15px 0;
  max-width: 700px;
}
.submit-profession {
  background-color: #c2114b;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  border-radius: 25px;
  padding: 4px 12px;
  border: none !important;
}
.edit_talentprofile .update-portfolio-title {
  padding: 0;
  margin: 0 0 15px;
}
.edit_talentprofile .edit-basicdetails-section-main .profileImg {
  position: relative;
  width: 250px;
  max-width: 100%;
  border-radius: 5px;
}
.edit_talentprofile .profile-image-edit-section {
  width: 100%;
}
.edit_talentprofile
  .edit-basicdetails-section-main
  .profileImg
  .profile-image-edit {
  min-width: 250px;
  -o-object-fit: cover;
  object-fit: cover;
  min-height: 250px;
}
.edit_talentprofile .profile-image-edit-section .btn-img-edit-wrapper {
  justify-content: inherit;
  width: auto;
  display: inline-block;
}
.edit_talentprofile
  .profile-image-edit-section
  .btn-img-edit-wrapper
  .update-profileimg-btn {
  margin: 10px 0;
}
.update-portfolio-title {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  padding: 20px 0 0 0;
}
.update-portfolio-label {
  color: #c2114b;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  padding: 10px 0;
}
.update-portfolio-cards-wrapper {
  box-shadow: rgb(60 64 67 / 0.3) 0 1px 2px 0,
    rgb(60 64 67 / 0.15) 0 1px 3px 1px;
  border-radius: 6px;
  padding: 15px;
  width: 100%;
  margin: 15px 0;
}
.update-service-cards-wrapper {
  box-shadow: rgb(60 64 67 / 0.3) 0 1px 2px 0,
    rgb(60 64 67 / 0.15) 0 1px 3px 1px;
  border-radius: 6px;
  padding: 30px;
  width: 100%;
  margin: 15px;
}
.update-portfolio-fileName {
  color: #6e6e6e;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  padding-left: 15px;
}
.update-portfolio-cards {
  box-shadow: rgb(50 50 93 / 0.25) 0 2px 5px -1px,
    rgb(0 0 0 / 0.3) 0 1px 3px -1px;
  padding: 10px;
  margin: 10px;
  border-radius: 2px;
  display: inline-block;
}
.service-files-main {
  display: flex;
  align-items: center;
}
.update-portfolio-section {
  display: flex;
  flex-wrap: wrap;
}
.update-portfolio-action {
  margin-left: 10px;
}
.bi-three-dots-vertical,
.bi-card-image,
.bi-mic-fill,
.bi-play-circle-fill,
.bi-file-earmark-richtext {
  color: #c2114b;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  box-shadow: rgb(50 50 93 / 0.25) 1px -1px 0 -2px,
    rgb(0 0 0 / 0.3) 0 0 3px -1px !important;
}

.dynamic-profession.newAlign .algSepc {
  width: calc(100% - 170px);
  float: left;
}
.dynamic-profession.newAlign .offer-wrapper {
  width: 170px;
  float: right;
  padding-left: 15px;
  justify-content: end;
}

.dynamic-profession.newAlign .algSepc .divSep {
  width: calc(100% / 5);
}

.dynamic-profession.newAlign .algSepc .profession-input {
  max-width: 100% !important;
  margin-right: 0px !important;
}

@media only screen and (max-width: 1399px) {
  .fixImgs .kids-image-sticky {
    width: 18%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 1199px) {
  .kids-form.col-md-8 {
    padding-left: 3%;
  }
  .dynamic-profession.newAlign .algSepc .divSep {
    padding-right: 0;
    margin-bottom: 0.4rem !important;
  }
  .dynamic-profession.newAlign {
    flex-direction: column;
    margin-bottom: 10px;
  }
  .dynamic-profession.newAlign .algSepc,
  .dynamic-profession.newAlign .offer-wrapper {
    width: 100%;
  }
  .dynamic-profession.newAlign .offer-wrapper {
    float: left;
    padding: 0 0 5px;
    justify-content: left;
  }
}
@media only screen and (max-width: 1149px) {
  .kids-form.col-md-8 {
    padding-left: 10px;
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .kidsform-one .kids-wrapper .kids-form .kids-form-title::after {
    width: 100%;
    margin-left: 15px;
  }
  .kidsform-one .kids-wrapper .kids-form .dynamic-profession {
    justify-content: center;
    flex-direction: column;
  }
  .kidsform-one .kids-wrapper .kids-form .dynamic-profession > .mb-3 {
    width: 100%;
    margin-bottom: 0.5rem !important;
  }
  .kidsform-one .kids-wrapper .kids-form .profession-input {
    max-width: 100%;
    margin-right: 0px !important;
  }

  .kids-main .media-wrapper .connect-btn {
    font-size: 14px;
  }
  .kids-form.col-md-8 {
    padding: 0 !important;
  }
}

.kids-main .media-wrapper .connect-btn {
  font-size: 14px;
}

.add-portfoli-section {
  display: flex;
  justify-content: end;
}
.edit_talentprofile .update-portfolio-cards {
  margin: 10px 0;
  display: block;
  width: 100%;
}
.edit_talentprofile .update-portfolio-cards {
  position: relative;
}
.edit_talentprofile .update-portfolio-cards .update-portfolio-action {
  position: absolute;
  right: 0;
}
.edit_talentprofile .edit-profileimg-btn {
  margin: 10px 0;
}
.edit_talentprofile .edit-basicdetails-section-main {
  padding: 0;
}
.edit_talentprofile .update-service-cards-wrapper,
.edit_talentprofile .update-portfolio-cards-wrapper,
.edit_talentprofile .profile-image-edit-section {
  margin: 0;
}
.edit-basicdetails-section-main {
  padding: 10px 30px;
  background-color: #fff;
  border-radius: 8px;
}
.edit-service-section-main {
  background-color: #fff;
}
.edit-service-section-wrapper {
  border-bottom: 1px solid #c2114b;
  margin-bottom: 30px;
  padding-bottom: 30px;
}
.no-data {
  font-size: 12px;
  color: red;
  font-family: sans-serif;
}
.edit-profileimg-btn:hover {
  background-color: #c2114b;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  text-align: right;
}
.update-password-main {
  padding: 30px;
  background-color: #fff;
  border-radius: 8px;
  width: 50%;
  margin: 0 auto;
}
.manage-account-main {
  padding: 30px;
  background-color: #fff;
  border-radius: 8px;
  width: 50%;
  margin: 0 auto;
}
.account-active-image {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  -o-object-fit: cover;
  object-fit: cover;
}
.talentSet .subtitles {
  margin: 10px 0;
  font-size: 16px;
  font-weight: 600;
  padding: 0;
}
.talentSet .edit-basicdetails-section-main {
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #ddd;
  margin: 15px 0 25px;
}
.talent-name {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 0;
}
.talent-account-status-title {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 0;
}
.talent-account-status-active {
  color: green;
  font-size: 13px;
  font-weight: 500;
  padding: 10px 0;
}
.talent-account-status-inactive {
  color: red;
  font-size: 13px;
  font-weight: 500;
  padding: 10px 0;
}
.add-more-services-btn {
  color: #c2114b;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;
}
.add-more-services-btn .bi-plus-circle-fill {
  padding-right: 8px;
}
.add-more-files-btn {
  display: inline-flex;
  align-items: center;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  background-color: green;
  cursor: pointer;
  padding: 8px 15px;
  border-radius: 6px;
}
.add-more-files-btn .bi-plus-circle-fill {
  padding-right: 8px;
  color: #fff;
}
.add-service-btn-flex {
  display: flex;
  justify-content: end;
}
.edit-profile-navigations {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
.bi-arrow-left-circle-fill,
.bi-arrow-right-circle-fill {
  font-size: 25px;
  cursor: pointer;
  color: #000;
}
.bi-arrow-right-circle-fill:hover {
  color: #c2114b;
}
.bi-arrow-left-circle-fill:hover {
  color: #c2114b;
}
.userName-n {
  font-size: 20px;
  padding: 10px 0 5px;
}
.edit-profile-navigation-text {
  font-size: 12px;
  font-weight: 400;
  color: #000;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
}
.update-password-main .password_strength_box {
  margin-top: 0;
}
.edit-profile-navigation-text:hover {
  font-size: 12px;
  font-weight: 500;
  color: #c2114b;
  cursor: pointer;
}
.edit-profile-navigation-btn {
  display: flex;
  align-items: center;
}
.edit-profile-navigation-btn:hover .bi-arrow-right-circle-fill {
  color: #c2114b;
}
.edit-profile-navigation-btn:hover .bi-arrow-left-circle-fill {
  color: #c2114b;
}
.edit-profile-navigation-btn:hover .edit-profile-navigation-text {
  color: #c2114b;
}
.image-edit-icon {
  background-color: #fff;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 0;
  right: 8px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 18px;
  border: 1px solid #c2114b;
}
.profile-edit-icon {
  font-size: 20px;
  color: #c2114b;
  cursor: pointer;
}
.edit-profileimg-btn:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
  opacity: 0.65;
}
.edit-service-header {
  display: flex;
  justify-content: space-between;
}
.bi-trash {
  cursor: pointer;
  font-size: 20px;
}
.bi-trash:hover {
  cursor: pointer;
  color: #c2114b;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedStart.css-1eed5fa-MuiInputBase-root-MuiInput-root {
  height: 100% !important;
  align-items: center !important;
}

textarea.contact-us-textarea.w-100 {
  font-size: 13px;
  border-color: #c5c5c5 !important;
  border-radius: 4px !important;
  padding: 10px;
}

.edit-basicdetails-section-main {
  min-width: 250px;
  -o-object-fit: cover;
  object-fit: cover;
  min-height: 250px;
}

.edit-profileimg-btn {
  background-color: #c2114b;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
  text-align: right;
  padding: 7px 15px;
}

.projects-completed::placeholder {
  font-size: 13px !important;
}

.address-textarea {
  height: 140px !important;
}

.material-mobile-style {
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  height: 45px;
  /* padding: 6px 0px 0px 10px !important; */
}
.set-rates {
  font-family: sans-serif;
  font-size: 14px;
  color: #666;
}

@media only screen and (max-width: 580px) {
  .dynamic-profession.newAlign .algSepc .divSep {
    width: 48%;
  }
  .edit-profileimg-btn {
    width: 100%;
  }
  .profile-image-edit-section {
    margin: 20px 0;
    padding: 0;
    width: 100%;
  }
}

.social-chain-icon {
  color: #c2114b;
  font-size: 26px;
}

.password-error-box {
  border-bottom: 2px solid red;
  padding: 8px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  width: 100% !important; /* Match the width of the input */
  box-sizing: border-box !important; /* Ensure padding is included in the width */
}

.otp {
  font-size: 14px !important;
  font-family: sans-serif;
  font-weight: 400;
}

.question-input-wrapper {
  display: flex;
  align-items: center;
}

.material-mobile-style .MuiInputAdornment-root img {
  display: block !important; /* Ensures the flag is always shown */
  width: auto !important; /* Adjust width if necessary */
  height: auto !important; /* Adjust height if necessary */
}

@supports (-webkit-touch-callout: none) {
  .material-mobile-style .MuiPhoneNumber-flag {
    display: inline-block;
  }
}

.material-mobile-style .MuiPhoneNumber-flag {
  display: inline-block !important;
  width: 24px !important; /* Adjust as per need */
  height: 16px !important;
  background-size: cover !important;
}
